<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <disorder-template
      :heading="$t('night-eating-syndrom')"
      :next="next"
      :previous="previous"
      :sources="sources"
    >
      <p
        v-for="paragraph in $t('disorders-night-eating-syndrom')"
        :key="paragraph"
      >
        {{ paragraph }}
      </p>
    </disorder-template>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import DisorderTemplate from "@/components/DisorderTemplate.vue";

export default {
  name: "night-eating-syndrom",
  components: {
    DisorderTemplate,
    Breadcrumbs
  },
  data: () => ({
    next: {
      slug: "pica-syndrom",
      url: "/disorders/pica-syndrom"
    },
    previous: {
      slug: "biggerexia",
      url: "/disorders/biggerexia"
    },
    sources: [
      "[1] Ärzteblatt DÄG Redaktion Deutsches. Night-Eating-Syndrom: Mehr als eine schlechte Angewohnheit. Deutsches Ärzteblatt. Published July 10, 2009. Accessed July 21, 2021. https://www.aerzteblatt.de/archiv/65341/Night-Eating-Syndrom-Mehr-als-eine-schlechte-Angewohnheit",
      "[2] ANRED: Night Eating Syndrome. Anred. Accessed July 21, 2021. https://www.anred.com/nes.html"
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("disorders"),
          disabled: false,
          href: "/disorders"
        },
        {
          text: this.$t("night-eating-syndrom"),
          disabled: true
        }
      ];
    }
  }
};
</script>
